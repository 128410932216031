import { useEffect, useState, useMemo } from 'react';
import { loadImage } from '@emulate/shared-images';
import { showComment } from '../../containers/RetailerAPI/SelectBrands';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

const ManufacturerItem = ({ manufacturer, selectedIds, handleSelect, onlyImage = false }) => {
    const [logoUrl, setLogoUrl] = useState(undefined);
    const [isImageError, setIsImageError] = useState(false);

    const sanitizedFileName = useMemo(() => {
        let fileName = onlyImage ? manufacturer : manufacturer.id;
        if (fileName.includes('own_') || fileName.includes('_old') || fileName.includes('_sc')) {
            fileName = fileName.replace(/own_|_old|_sc/g, '');
        }
        return fileName;
    }, [manufacturer, onlyImage]);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const url = await loadImage(`${sanitizedFileName}.png`);
                setLogoUrl(url);
                setIsImageError(false);
            } catch (error) {
                console.warn(`Failed to load image for ${sanitizedFileName}:`, error);
                setLogoUrl('/assets/icons/logos/blank.png');
                setIsImageError(true);
            }
        };

        if (!isImageError) {
            fetchImage();
        }
    }, [sanitizedFileName, isImageError]);

    const handleImageError = e => {
        e.target.src = '/assets/icons/logos/blank.png';
        setIsImageError(true);
    };

    if (onlyImage) {
        return <img className="select-manufacturers__brand-item--brand-logo" src={logoUrl} alt={manufacturer.label} onError={handleImageError} />;
    }

    return (
        <div className="select-manufacturers__brand-item" key={manufacturer.id}>
            <div className="select-manufacturers__brand-item--select-brand-checkbox">
                <input
                    type="checkbox"
                    name="manufacturer"
                    id={`manufacturer-${manufacturer.id}`}
                    checked={selectedIds.includes(manufacturer.id)}
                    onChange={() => handleSelect(manufacturer.id)}
                />
                <label htmlFor={`manufacturer-${manufacturer.id}`}>{manufacturer.label.toUpperCase()}</label>
                {manufacturer.state !== 'stable' && manufacturer.comment && manufacturer.comment !== '' && (
                    <InfoTooltip tooltip={showComment(manufacturer)} />
                )}
            </div>
            <img className="select-manufacturers__brand-item--brand-logo" src={logoUrl} alt={manufacturer.label} onError={handleImageError} />
        </div>
    );
};

export default ManufacturerItem;
